import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { InjectionKey } from 'vue'
import RootStateTypes, { AllStateTypes } from '@/store/interface'
import storage from '@/utils/storage'
import testA from './modules/testA'
import testB from './modules/testB'
export default createStore<RootStateTypes>({
  state: {
    user: storage.get('user') || null,
    token: storage.get('token') || '',
    isLogin: storage.get('token') ? true : false,
  },
  modules: {
    testA,
    testB
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    changeUser(state, payLoad) {
      state.user = payLoad
    },
    changeToken(state, payLoad) {
      state.token = payLoad
    },
    changeLoginStatus(state, payLoad) {
      state.isLogin = payLoad
    }
  },
  actions: {
    login({ commit }, payLoad) {
      const { user, token } = payLoad
      user && (commit('changeUser', user), storage.set('user', user))
      token && (commit('changeToken', token), storage.set('token', token))
      commit('changeLoginStatus', true)
    },
    logout({ commit }) {
      commit('changeUser', null)
      commit('changeToken', '')
      commit('changeLoginStatus', false)
      storage.remove('user')
      storage.remove('token')
    }
  }
})

export const key: InjectionKey<Store<RootStateTypes>> = Symbol('vue-store');

export const useStore = <T = AllStateTypes>(): any => {
  return baseUseStore<T>(key);
}
