import { RouteRecordRaw } from 'vue-router'
const getNopage: Array<RouteRecordRaw> = [
  {
    path: '/explore',
    name: 'explore',
    component: () => import('@v/pages/explore/explore.vue'),
    meta: { title: '碳索家' },
  },{
    path: '/news/article',
    name: 'article',
    component: () => import('@v/pages/news/article.vue'),
    meta: { title: '新闻文章页' },
  },{
    path: '/news/info',
    name: 'newsInfo',
    component: () => import('@v/pages/news/newsInfo.vue'),
    meta: { title: '新闻列表' },
  },{
    path: '/content/imgcase',
    name: 'imgCase',
    component: () => import('@v/pages/content/imgCase.vue'),
    meta: { title: '图片类案列' },
  },{
    path: '/about',
    name: 'about',
    component: () => import('@v/pages/about/index.vue'),
    meta: { title: '关于' },
  },{
    path: '/ppp',
    name: 'ppp',
    component: () => import('@v/pages/ppp/index.vue'),
    meta: { title: 'ppp专题页' },
  },{
    path: '/health',
    name: 'health',
    component: () => import('@v/pages/health/index.vue'),
    meta: { title: '健康专题页' },
  },{
    path: '/hezuo',
    name: 'hezuo',
    component: () => import('@v/pages/hezuo/news/index.vue'),
    meta: { title: '合作联盟' },
  }
]
export default getNopage