/* 对localStorage进行封装 sessionStorage */
const storage = {
    set(key: string, value: any) {
      if (value) {
        value = JSON.stringify(value);
      }
      window.localStorage.setItem(key, value);
    },
    get(key: string): any {
      const value: string | null = window.localStorage.getItem(key);
      if (value && value !== 'undefined' && value !== 'null') {
        return JSON.parse(value);
      }
      return null;
    },
    remove(key: string) {
      return window.localStorage.removeItem(key)
    },
    clear() {
      return window.localStorage.clear()
    }
  }
  
  export default storage