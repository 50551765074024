import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const createNewRouter = () => createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'is-active',
  linkExactActiveClass: '',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition参数，点击浏览器左右箭头会触发  && to.meta.keepAlive
    if (savedPosition) {
      return savedPosition
    }
    
    // iOS设备页面返回出现空白屏问题
    return new Promise((resolve) => {
      window.setTimeout(() => {
        // 这里可以返回很多参数，下面简单列就几个
        resolve({ top: 0, left: 0 });
      });
    });
  },
})

const router = createNewRouter()
router.beforeEach((to, from, next) => {
  next()
});
// router.afterEach((to, from, next) => {
//   console.log(!to.meta.keep)
//   if (!to.meta.keep) {
//     window.scrollTo(0, 0);
//   }
// });

export default router