import { RouteRecordRaw } from 'vue-router'
import content from './content'
import zhuRoute from './zhuRoute'
const getNopage: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/home',
    component: () => import('@v/layout/layout.vue'),
    meta: { title: '布局' },
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@v/pages/home/home.vue'),
        meta: { title: '首页', keepAlive: true },
      },
      ...content,
      ...zhuRoute
    ]
  }
]
export default getNopage