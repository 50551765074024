import { RouteRecordRaw } from 'vue-router'
const getNopage: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@v/nopage/index.vue'),
    // component: modules[`../views/nopage/index.vue`],
    meta: { title: '页面不存在' }
  }
]
export default getNopage