import { Module } from 'vuex'
import RootStateTypes from '@/store/interface'
import TestModuleTypes from '@/store/modules/testB/interface'

const testModule:Module<TestModuleTypes,RootStateTypes> = {
  namespaced: import.meta.env.NODE_ENV !== 'production',
  state: {
    name: 'testB',
    age: 20
  },
  mutations: {
    CHANGE_AGE(state, payLoad){
      state.age = payLoad
    },
    CHANGE_NAME(state, payLoad) {
      state.name = payLoad
    }
  },
  actions: {
    addAge({commit, state}) {
      let age = state.age + 1
      commit('CHANGE_AGE', age)
    },
    changeAge({commit}, payLoad){
      commit('CHANGE_AGE', payLoad)
    },
    changeName({commit}, payLoad){
      commit('CHANGE_NAME', payLoad)
    },
    test({commit}, payLoad){
      payLoad.age && commit('CHANGE_AGE', payLoad.age)
      payLoad.name && commit('CHANGE_NAME', payLoad.name)
    },
    bToa({dispatch}) {
      // 参数一：是其他模块的 actions 路径,。
      // 参数二：是传给 actions 的数据, 如果不需要传数据, 也必须预留,
      // 参数三：是配置选项, 申明这个 acitons 不是当前模块的
      dispatch('testA/demo', '我是b,我却要调用a' ,{root: true})
    },
    demo({},payLoad) {
      console.log(payLoad)
    }
  }
}

export default testModule