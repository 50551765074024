import { RouteRecordRaw } from 'vue-router'
const zhuRoute: Array<RouteRecordRaw> = [
  {
    path: '/video',
    name: 'video',
    component: () => import('@v/pages/video/video.vue'),
    meta: { title: '视频内容页', keepAlive: true },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('@v/pages/project/project.vue'),
    meta: { title: '项目中心', keepAlive: true },
  },
  {
    path: '/project/list',
    name: 'projectlist',
    component: () => import('@v/pages/project/list.vue'),
    meta: { title: '项目列表', keepAlive: true },
  },
  {
    path: '/redletter',
    name: 'redletter',
    component: () => import('@v/pages/redletter/redletter.vue'),
    meta: { title: '红色家书' },
  }
]
export default zhuRoute